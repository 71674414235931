<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('course_and_slot_logs')" :isFilter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('course_and_slot_logs')" :isFilter="false"/>
            </template>
            <ValidationObserver ref="filterForm">
                <b-row>
                    <b-col cols="12" md="6">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox v-model="filter.academic_year"></academic-years-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-form-group :label="$t('student_number')">
                            <b-form-input type="number" v-model="filter.student_number" v-check-min-max-value></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-form-group :label="$t('semester')">
                            <parameter-selectbox code="semester" v-model="filter.semester"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                        <ValidationProvider name="semester" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('log_type')">
                                <log-type-selectbox
                                    v-model="filter.log_type" :validate-error="errors[0]"
                                ></log-type-selectbox>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="6" md="6">
                        <b-form-group :label="$t('start_date') + ' / ' + $t('end_date')">
                            <ValidationProvider name="date" rules="required" v-slot="{valid, errors}">
                                <v-date-picker
                                    v-model="date"
                                    is-range
                                    :model-config="{type: 'string', mask: 'MM/DD/YYYY'}"
                                    locale="en"
                                >
                                    <template v-slot="{ inputValue, inputEvents }">
                                        <div class="border rounded-sm p-1 p-md-3 d-flex align-items-center" :class="errors[0] ? 'box-border-color':''">
                                            <div class="flex-grow-1">
                                                <b-form-group class="m-0 mr-1">
                                                    <input
                                                        :value="inputValue.start"
                                                        v-on="inputEvents.start"
                                                        :placeholder="$t('start_date')"
                                                        class="form-control"
                                                    />
                                                </b-form-group>
                                            </div>
                                            <div class="flex-grow-1">
                                                <b-form-group class="m-0 ml-1">
                                                    <input
                                                        :value="inputValue.end"
                                                        v-on="inputEvents.end"
                                                        :placeholder="$t('end_date')"
                                                        class="form-control"
                                                    />
                                                </b-form-group>
                                            </div>
                                        </div>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </template>
                                </v-date-picker>
                            </ValidationProvider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-button variant="primary" @click="getReport">
                            {{ $t('get_report').toUpper() }}
                        </b-button>
                    </b-col>
                </b-row>
            </ValidationObserver>
        </app-layout>
    </div>
</template>

<script>
import AppLayout from '@/layouts/AppLayout'
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
import AcademicYearsSelectbox from '@/components/interactive-fields/AcademicYearsSelectbox'
import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox'
import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox'
import LogTypeSelectbox from '@/components/interactive-fields/LogTypeSelectbox'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import SettingsService from '@/services/SettingsService'
import qs from 'qs'

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        AcademicYearsSelectbox,
        ParameterSelectbox,
        FacultySelectbox,
        LogTypeSelectbox,
        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            filter: {},
            date: {}
        }
    },
    metaInfo() {
        return {
            title: this.$t('course_and_slot_logs')
        }
    },
    methods: {
        async getReport() {
            const isValid = await this.$refs.filterForm.validate()

            if (isValid) {
                if(this.date.start) {
                    this.filter.start_date = this.date.start
                    this.filter.end_date = this.date.end
                }

                const config = {
                    params: this.filter,
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }

                SettingsService.getCourseSlotLogs(config)
                    .then((response) => {
                        this.$toast.success(this.$t('api.' + response.data.message))
                        this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                    })
                    .catch((error) => {
                        this.$toast.error(this.$t('api.' + error.data.message))
                    })
                }
        }
    }
}
</script>
